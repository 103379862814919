<!--我的资料，收藏，回复等-->
<template>
    <div class="my">
        <el-tabs v-model="activeName" :tab-position="tabPosition" @tab-click="handleTab"
                 :style="{marginTop: tabPosition === 'top' ? 0 : '20px'}">
            <el-tab-pane label="个人资料" name="info">
                <my-info></my-info>
            </el-tab-pane>
            <el-tab-pane label="修改密码" name="password">
                <my-password></my-password>
            </el-tab-pane>
            <el-tab-pane label="我的收藏" name="collect">
                <my-collect ref="myCollect"></my-collect>
            </el-tab-pane>
            <el-tab-pane label="我的发布" name="publish">
                <my-list ref="myList"></my-list>
            </el-tab-pane>
          <!--  <el-tab-pane label="我的回复" name="reply">
            </el-tab-pane>-->
        </el-tabs>
    </div>
</template>

<script>
    import Vue from 'vue';
    import {Tabs, TabPane} from 'element-ui';

    Vue.use(Tabs);
    Vue.use(TabPane);
    import myInfo from './MyInfo.vue';
    import myPassword from './MyPassword.vue';
    import myList from './MyList.vue';
    import myCollect from './MyCollect.vue';

    export default {
        name: "My",
        components: {
            myInfo, myPassword, myList, myCollect
        },
        computed: {
            // tabs所在位置，屏幕小于500就在上面
            tabPosition() {
                const w = window.screen.width;
                return w < 500 ? 'top' : 'left'
            }
        },
        data() {
            return {
                activeName: 'info',
            }
        },
        methods: {
            // 点击tabs
            handleTab(e){
                switch (e.name) {
                    case 'collect':
                        this.$refs.myCollect.getList();
                        break;
                    case 'publish':
                        this.$refs.myList.getList();
                        break;
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
</style>