<!--个人资料页面-->
<template>
    <div>
        <el-form ref="infoForm" :model="info" :rules="rulesInfo" label-width="50px" class="info-form" v-if="edit">
            <el-form-item prop="name" label="账号">
                <el-input v-model="info.user_name" placeholder="请输入账号名"></el-input>
            </el-form-item>
            <div class="flex justify-around info-handle">
                <el-button size="small" @click="editCancel">取消</el-button>
                <el-button size="small" type="primary" @click="editConfirm" :loading="btnLoading">确定</el-button>
            </div>
        </el-form>
        <div class="info-form" v-else>
            <el-row class="info-list">
                <el-col :span="6" class="color-gray">账号：</el-col>
                <el-col :span="18" class="color-black">{{userInfo.user_name}}</el-col>
            </el-row>
            <el-row class="info-list">
                <el-col :span="6" class="color-gray">经验：</el-col>
                <el-col :span="18" class="color-black">
                    <span class="mar-rig">{{userInfo.exp_num}}/{{getExpLevel().exp_num}}</span>
                    <span class="font-bold color-jingxiang">等级 {{getExpLevel().level}}</span>
                </el-col>
            </el-row>
            <div class="flex justify-around info-handle">
                <el-button size="small" @click="editOpen">编辑</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import {Row, Col} from 'element-ui';
    import app from '@/utils/app.js';
    import {user} from '../../api/api.js';

    Vue.use(Row);
    Vue.use(Col);
    export default {
        name: "MyInfo",
        computed:{
            userInfo(){
                return this.$store.state.userInfo;
            }
        },
        data() {
            return {
                info: {}, // 修改中的数据
                rulesInfo: {
                    user_name: [
                        {required: true, message: '请输入账号名', trigger: 'blur'}
                    ]
                },
                edit: false, // 是否是编辑状态
                btnLoading: false, // 确定按钮的loading
            }
        },
        methods: {
            // 取消编辑
            editCancel(){
                this.$refs['infoForm'].resetFields();
                this.edit = false;
            },
            // 确定编辑
            editConfirm(){
                this.$refs['infoForm'].validate(async (valid) => {
                    if (valid) {
                        const {info} = this;
                        const params = {
                          new_user_name: info.user_name,
                        };
                        this.btnLoading = true;
                        const req = await app.ajax(user.updateInfo,params);
                        this.btnLoading = false;
                        if(req.code === 1){
                            app.alert('修改成功', 'success');
                            await this.$store.dispatch('getUserInfo');
                            this.editCancel();
                        } else {
                            app.alert(req.msg, 'error');
                        }
                    }
                });
            },
            // 点击编辑
            editOpen(){
                this.edit = true;
                this.info = {...this.userInfo};
            },
            // 获取经验值等级
            getExpLevel(){
                return app.getExpLevel(this.userInfo.exp_num);
            }

        }
    }
</script>

<style lang="scss" scoped>

    .info-form {
        max-width: 300px;
        padding: 10px 20px;

        .info-list {
            padding: 20px 0;
        }

        .info-handle {
            padding: 20px;
        }
    }
</style>