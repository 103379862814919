<!--我的发布-->
<template>
    <div v-loading="listLoading">
        <el-table
                :data="dataList"
                stripe
                row-key="id"
                style="width: 100%"
        >
            <el-table-column
                    prop="bug_title"
                    label="标题">
                <template slot-scope="scope">
                    <div :class="scope.row.bug_status === '1' ? 'color-xiaofu cursor-hand title-name' : 'title-del color-gray-more'"
                         @click="handleRowClick(scope.row)"
                         :title="scope.row.bug_status !== '1' ? '该文章已删除或不存在' : '点击查看文章详情'">{{scope.row.bug_title}}
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                    prop="type_name"
                    label="分类"
                    align="center"
                    width="100">
            </el-table-column>
            <el-table-column
                    prop="create_time"
                    label="发布时间"
                    align="center"
                    width="100">
            </el-table-column>
            <el-table-column
                    prop="date"
                    label="操作"
                    align="center"
                    width="100">
                <template slot-scope="scope">
                    <el-button type="text" size="small" :style="{color: color_meimei}"
                               @click="cancelCollect(scope.row)">删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <!--        分页-->
        <div class="text-right" :style="{padding: `${pageCss.padding || 20}px 0`}">
            <el-pagination
                    :small="pageCss.small || false"
                    :pager-count="pageCss.pager_count || 9"
                    background
                    layout="prev, pager, next"
                    :total="total"
                    :page-size="size"
                    :current-page.sync="page"
                    @current-change="getList"
            >
            </el-pagination>
        </div>
    </div>
</template>

<script>
    import app from '../../utils/app.js';
    import {bugApi, collectApi} from "../../api/api";
    import {MessageBox} from "element-ui";

    export default {
        name: "MyList",
        data() {
            return {
                color_meimei: app.color_meiemi,
                dataList: [],
                listLoading: false, // 表格loading
                page: 1,
                size: 10,
                total: 0,
            }
        },
        computed: {
            // 分页页码相关,自适应兼容处理
            pageCss() {
                return this.$store.state.pageCss;
            }
        },
        methods: {
            // 点击标题
            handleRowClick(row) {
                if (row.bug_status === '1') {
                    window.open(`/detail?id=${row.bug_id}`)
                }
            },
            // 查询列表
            async getList() {
                this.listLoading = true;
                const {page, size} = this;
                const params = {
                    page,
                    size
                }
                const req = await app.ajax(bugApi.searchMyList, params);
                this.listLoading = false;
                this.total = req.total || 0;
                if (req.code === 1) {
                    this.dataList = req.data || [];
                } else {
                    app.alert(req.msg, 'error');
                }
            },

            // 删除bug
            cancelCollect(row) {
                MessageBox.confirm('是否确定删除？', '', {
                    confirmButtonText: '删除',
                    cancelButtonText: '取消',
                    type: 'warning',
                    beforeClose: async (action, instance, done) => {
                        if (action === 'confirm') {
                            instance.confirmButtonLoading = true;
                            instance.confirmButtonText = '删除中...';
                            const req = await app.ajax(bugApi.updateBug, {bug_id: row.bug_id});
                            done();
                            instance.confirmButtonLoading = false;
                            if (req.code === 1) {
                                app.alert('删除成功', 'success');
                                const {dataList, page} = this;
                                if (dataList.length === 1) {
                                    this.page = (page - 1) < 1 ? 1 : (page - 1);
                                }
                                await this.getList();
                            } else {
                                app.alert(req.msg, 'error')
                            }
                        } else {
                            done();
                        }
                    }
                }).then(() => {
                }).catch(() => {
                });
            }
        }
    }
</script>

<style lang="scss" scoped>
    .title-name:hover {
        font-weight: bold;
    }

    .title-del {
        text-decoration: line-through;;
    }
</style>