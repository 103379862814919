<!--修改密码页面-->
<template>
    <div>
        <el-form ref="passwordForm" :model="pData" :rules="rulesPassword" label-width="80px" class="info-form">
            <el-form-item prop="password" label="原密码">
                <el-input v-model="pData.password" show-password placeholder="请输入原密码"></el-input>
            </el-form-item>
            <el-form-item prop="new_password" label="新密码">
                <el-input v-model="pData.new_password" show-password placeholder="请输入新密码"></el-input>
            </el-form-item>
            <el-form-item prop="again_password" label="再次输入">
                <el-input v-model="pData.again_password" show-password placeholder="请再次输入新密码"></el-input>
            </el-form-item>
            <div class="flex justify-around info-handle">
                <el-button size="small" type="primary" @click="editConfirm" :loading="subLoading">确定</el-button>
            </div>
        </el-form>
    </div>
</template>

<script>
    import app from '../../utils/app';
    import {user} from "../../api/api";

    export default {
        name: "MyPassword",
        data() {
            const validateAgainPass = (rule, value, callback) => {
                if (!value) {
                    callback(new Error('请再次输入密码'));
                } else if (value !== this.pData.new_password) {
                    callback(new Error('两次输入密码不一致!'));
                } else {
                    callback();
                }
            };
            return {
                pData: { // 修改中的数据
                    new_password: '',
                    password: '',
                    again_password: '',
                },
                rulesPassword: {
                    password: [
                        {required: true, message: '请输入原来的密码', trigger: 'blur'}
                    ],
                    new_password: [
                        {required: true, message: '请输入新密码', trigger: 'blur'}
                    ],
                    again_password: [
                        {required: true, trigger: 'blur', validator: validateAgainPass}
                    ],
                },
                subLoading: false,
            }
        },
        methods: {
            // 确定编辑
            editConfirm() {
                this.$refs['passwordForm'].validate(async (valid) => {
                    if (valid) {
                        this.subLoading = true;
                        const {pData} = this,
                            params = {
                                password: app.md5(pData.password),
                                new_password: app.md5(pData.new_password)
                            }
                        const req = await app.ajax(user.updatePassword, params);
                        this.subLoading = false;
                        if (req.code === 1) {
                            app.alert(req.msg, 'success');
                            this.pData = {
                                new_password: '',
                                password: '',
                                again_password: '',
                            };
                        } else {
                            app.alert(req.msg, 'error');
                        }
                    }
                });
            },
        }
    }
</script>

<style lang="scss" scoped>

    .info-form {
        max-width: 300px;
        padding: 10px 20px;

        .info-list {
            padding: 20px 0;
        }

        .info-handle {
            padding: 20px;
        }
    }
</style>